import { DATA_TYPES } from "../../types";

export class Relation {
  constructor(
    public readonly __typename: DATA_TYPES,
    public readonly id: string
  ) {}
}

export const getRelation = <T extends unknown>(
  __typename: DATA_TYPES,
  id: string
) => new Relation(__typename, id) as T;
