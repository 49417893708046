import * as R from "ramda";

import { SortParams } from "../../../types";
import { IndexItem } from "../../types";

export const sortData = R.curry(
  (sortParams: SortParams | undefined, data: Array<IndexItem>) => {
    const sortParam = sortParams && R.head(sortParams);
    if (!sortParam) {
      return data;
    }

    const [field, direction] = Object.entries(sortParam)[0];

    return data.sort((a: IndexItem, b: IndexItem) => {
      const aValue = String(a[field]);
      const bValue = String(b[field]);

      if (aValue < bValue) {
        return direction === "asc" ? -1 : 1;
      } else if (aValue > bValue) {
        return direction === "asc" ? 1 : -1;
      }

      return 0;
    });
  }
);
