import * as R from "ramda";

interface PageData<T> {
  data: Array<T>;
  total: number;
}

export const paginateData = R.curry(
  <T>(
    offset: number | undefined,
    limit: number | undefined,
    data: Array<T>
  ): PageData<T> => ({
    data:
      typeof offset !== "undefined" && typeof limit !== "undefined"
        ? data.slice(offset, offset + limit)
        : data,
    total: data.length,
  })
);
