import { assetHandlers } from "./handlers/assests";
import { deploymentsHandlers } from "./handlers/deployments";
import { eventsHandlers } from "./handlers/events";
import { logsHandlers } from "./handlers/logs";
import { projectsHandlers } from "./handlers/projects";

export const handlers = [
  ...deploymentsHandlers,
  ...eventsHandlers,
  ...logsHandlers,
  ...projectsHandlers,
  ...assetHandlers,
];
