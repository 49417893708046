import "antd/dist/antd.css";

import VwcCircularProgress from "@vonage/vivid-react/VwcCircularProgress";
import { lazy, Suspense } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import { ErrorCatcher } from "./components/ErrorCatcher/ErrorCatcher";
import * as S from "./styles";

const Projects = lazy(() => import("./features/Projects/Projects"));
const Project = lazy(() => import("./features/Projects/Project"));

function App() {
  return (
    <ErrorCatcher>
      <Router>
        <Suspense
          fallback={
            <S.Center>
              <VwcCircularProgress />
            </S.Center>
          }
        >
          <Switch>
            <Route component={Project} exact path="/:projectName" />
            <Route component={Projects} exact path="/" />
          </Switch>
        </Suspense>
      </Router>
    </ErrorCatcher>
  );
}

export default App;
