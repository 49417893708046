import { Button, Result } from "antd";

interface ErrorPageProps {
  error?: Error;
}

export const ErrorPage = ({ error }: ErrorPageProps) => (
  <Result
    extra={[
      <Button href="/" key="home" type="primary">
        Take me back
      </Button>,
    ]}
    status="error"
    subTitle={
      error ? error.message : "An unexpected error occurred. Sorry about that!"
    }
    title="Oops"
  ></Result>
);
