import { graphql } from "msw";
import * as R from "ramda";

import { GetLogLevelsQuery, Logs } from "../../generated/graphql";
import { schema } from "../data";
import { DATA_TYPES } from "../types";
import { getRelations } from "../utils/data-relations/resolve-relations";
import { filterData } from "../utils/middleware/filter";
import { paginateData } from "../utils/middleware/paginate";
import { makeResponseObject } from "../utils/middleware/response-object";
import { sortData } from "../utils/middleware/sort";

const responseObject = makeResponseObject(DATA_TYPES.log);

export const logsHandlers = [
  graphql.query("getLogs", (req, res, ctx) => {
    const allLogs = schema[DATA_TYPES.log];
    const { limit, offset, orderBy, where } = req.variables;

    const filterRules = filterData(where);
    const sortRules = sortData(orderBy);
    const paginateRules = paginateData(offset, limit);

    const result = R.pipe(
      getRelations,
      filterRules,
      sortRules,
      paginateRules,
      responseObject
    )(allLogs);

    return res(ctx.data(result));
  }),

  graphql.query("getLogLevels", (req, res, ctx) => {
    const { where } = req.variables;
    const allLogs = schema[DATA_TYPES.log];

    const filterRules = filterData(where);
    const sortRules = sortData([{ logLevel: "asc" }]);
    const reducer = ({ LogLevels }: GetLogLevelsQuery, { logLevel }: Logs) => ({
      LogLevels: LogLevels.concat([{ logLevel }]),
    });

    const result = R.pipe(
      filterRules,
      sortRules,
      R.reduce(reducer, { LogLevels: [] })
    )(allLogs);

    return res(ctx.data(result));
  }),
];
