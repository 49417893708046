import { Applications, Deployments } from "../../generated/graphql";
import { DATA_TYPES } from "../types";
import { getRelation } from "../utils/data-relations/relation";

const deployments: Array<
  Pick<
    Deployments,
    | "id"
    | "deploymentName"
    | "applicationName"
    | "created"
    | "Application"
    | "conf"
  >
> = [
  {
    Application: getRelation<Applications>(DATA_TYPES.project, "projFoo"),
    applicationName: "projFoo-dev",
    conf: {
      key1: "value1",
    },
    created: "2021-06-15T09:30:11.401162",
    deploymentName: "dev",
    id: "deplFoo",
  },
  {
    Application: getRelation<Applications>(DATA_TYPES.project, "projBar"),
    applicationName: "projBar-prod",
    created: "2021-06-15T09:30:11.401162",
    deploymentName: "prod",
    id: "deplBar",
  },
  {
    Application: getRelation<Applications>(DATA_TYPES.project, "projFoo"),
    applicationName: "projFoo-prod",
    created: "2021-06-15T09:30:11.401162",
    deploymentName: "prod",
    id: "deplBaz",
  },
  {
    Application: getRelation<Applications>(DATA_TYPES.project, "projBar"),
    applicationName: "projBar-staging",
    created: "2021-06-15T09:30:11.401162",
    deploymentName: "staging",
    id: "deplQux",
  },
  {
    Application: getRelation<Applications>(DATA_TYPES.project, "projBar"),
    applicationName: "projBar-dev",
    created: "2021-06-15T09:30:11.401162",
    deploymentName: "dev",
    id: "deplQuux",
  },
  {
    Application: getRelation<Applications>(DATA_TYPES.project, "projBaz"),
    applicationName: "projBaz-staging",
    created: "2021-06-15T09:30:11.401162",
    deploymentName: "staging",
    id: "deplCorge",
  },
  {
    Application: getRelation<Applications>(DATA_TYPES.project, "projFoo"),
    applicationName: "projFoo-dev",
    created: "2021-06-15T09:30:11.401162",
    deploymentName: "staging",
    id: "deplGrault",
  },
  {
    Application: getRelation<Applications>(DATA_TYPES.project, "projBaz"),
    applicationName: "projBaz-staging",
    created: "2021-06-15T09:30:11.401162",
    deploymentName: "dev",
    id: "deplGarply",
  },
];

export default deployments;
