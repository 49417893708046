import * as R from "ramda";

import { FILTER_TYPES, FilterParams, FilterTypes } from "../../../types";
import { IndexItem } from "../../types";

const isObject = <T>(item: unknown): item is T => R.type(item) === "Object";

const simpleFilter = <T extends IndexItem>(
  item: T,
  field: string,
  kind: string,
  value: string
) => {
  switch (kind) {
    case FILTER_TYPES._eq:
      return item[field] === value;
    case FILTER_TYPES._gte:
      const gteValue = item[field];
      if (typeof gteValue === "string") {
        // assume it's a date string
        return Date.parse(gteValue) >= Date.parse(value);
      }
      return false;
    case FILTER_TYPES._lt:
      const ltValue = item[field];
      if (typeof ltValue === "string") {
        // assume it's a date string
        return Date.parse(ltValue) < Date.parse(value);
      }
      return false;
    case FILTER_TYPES._like:
      const likeValue = item[field];
      if (typeof likeValue === "string") {
        return likeValue.includes(value.replace(/%/g, ""));
      }
      return false;
    case FILTER_TYPES._ilike:
      const ilikeValue = item[field];
      if (typeof ilikeValue === "string") {
        return ilikeValue
          .toLowerCase()
          .includes(value.replace(/%/g, "").toLowerCase());
      }
      return false;
    default:
      throw new Error("Filter rule is incorrect.");
  }
};

const traverseFilterEntry = <T extends IndexItem>(
  item: T,
  [field, rule]: [field: string, rule: FilterTypes]
) =>
  Object.entries(rule).reduce(
    (verdict, [kind, rule]) =>
      verdict && simpleFilter<T>(item, field, kind, rule),
    true
  );

const traverseFilter = (
  item: IndexItem,
  filterEntry: [string, FilterParams | FilterTypes],
  verdict: boolean
): boolean => {
  const [key, entry] = filterEntry;
  const nestedItem = item[key];

  if (isObject<IndexItem>(nestedItem) && isObject<FilterParams>(entry)) {
    const nextEntry = Object.entries(entry).pop();
    if (!nextEntry) {
      throw new Error(
        `Filter structure with key ${key} doesn't match data structure.`
      );
    }
    return traverseFilter(nestedItem, nextEntry, verdict);
  } else {
    return verdict && traverseFilterEntry<IndexItem>(item, filterEntry);
  }
};

export const filterData = R.curry(
  (filter: FilterParams | undefined, data: Array<IndexItem>) =>
    filter
      ? data.filter((item) => {
          return Object.entries(filter).reduce(
            (verdict, filterEntry) =>
              traverseFilter(item, filterEntry, verdict),
            Boolean(true)
          );
        })
      : data
);
