import { graphql } from "msw";
import * as R from "ramda";

import { Events, GetEventsFormDataQuery } from "../../generated/graphql";
import { schema } from "../data";
import { DATA_TYPES } from "../types";
import { getRelations } from "../utils/data-relations/resolve-relations";
import { filterData } from "../utils/middleware/filter";
import { paginateData } from "../utils/middleware/paginate";
import { makeResponseObject } from "../utils/middleware/response-object";
import { sortData } from "../utils/middleware/sort";

const responseObject = makeResponseObject(DATA_TYPES.event);

export const eventsHandlers = [
  graphql.query("getEvents", (req, res, ctx) => {
    const { limit, offset, orderBy, where } = req.variables;
    const allEvents = schema[DATA_TYPES.event];

    const filterRules = filterData(where);
    const sortRules = sortData(orderBy);
    const paginateRules = paginateData(offset, limit);

    const result = R.pipe(
      getRelations,
      filterRules,
      sortRules,
      paginateRules,
      responseObject
    )(allEvents);

    return res(ctx.data(result));
  }),

  graphql.query("getEventsFormData", (req, res, ctx) => {
    const { where } = req.variables;
    const allEvents = schema[DATA_TYPES.event];

    const filterRules = filterData(where);
    const sortRules = sortData([{ eventType: "asc" }]);
    const reducer = (
      { EventTypes, SourceTypes }: GetEventsFormDataQuery,
      { eventType, sourceType }: Events
    ) => ({
      EventTypes: EventTypes.concat([{ eventType }]),
      SourceTypes: SourceTypes.concat([{ sourceType }]),
    });

    const result = R.pipe(
      filterRules,
      sortRules,
      R.reduce(reducer, { EventTypes: [], SourceTypes: [] })
    )(allEvents);

    return res(ctx.data(result));
  }),
];
