import { Component, ComponentType } from "react";

import { ErrorPage } from "./ErrorPage";

interface ErrorCatcherProps {
  component?: ComponentType<{
    component: string;
    error?: Error;
    hasError?: boolean;
  }>;
}

interface ErrorCatcherState {
  error?: Error;
  hasError: boolean;
}

export class ErrorCatcher extends Component<
  ErrorCatcherProps,
  ErrorCatcherState
> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): ErrorCatcherState {
    return { error, hasError: true };
  }

  componentDidCatch(error: Error | undefined, errorInfo: object | undefined) {
    console.log("Unhandled error", error, errorInfo); // TODO: add proper error reporting
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage error={this.state.error} />;
    }

    return this.props.children;
  }
}
