import { graphql } from "msw";
import * as R from "ramda";

import { Applications } from "../../generated/graphql";
import { schema } from "../data";
import { DATA_TYPES } from "../types";
import { getRelations } from "../utils/data-relations/resolve-relations";
import { filterData } from "../utils/middleware/filter";
import { paginateData } from "../utils/middleware/paginate";
import { makeResponseObject } from "../utils/middleware/response-object";
import { sortData } from "../utils/middleware/sort";

const responseObject = makeResponseObject(DATA_TYPES.project);

export const projectsHandlers = [
  graphql.query("getProjects", (req, res, ctx) => {
    const allProjects = schema[DATA_TYPES.project];
    const { limit, offset, orderBy, where } = req.variables;

    const filterRules = filterData(where);
    const sortRules = sortData(orderBy);
    const paginateRules = paginateData(offset, limit);

    const result = R.pipe(
      getRelations,
      filterRules,
      sortRules,
      paginateRules,
      responseObject
    )(allProjects);

    return res(ctx.data(result));
  }),

  graphql.query("getProject", (req, res, ctx) => {
    const allProjects = schema[DATA_TYPES.project];
    const { where } = req.variables;

    const filterRules = filterData(where);

    const distinctDeploymentNames = (data: Array<Applications>) =>
      data.map((project) => ({
        ...project,
        Deployments: R.uniqBy(R.prop("applicationName"), project.Deployments),
      }));

    const result = R.pipe(
      getRelations,
      filterRules,
      distinctDeploymentNames,
      responseObject
    )(allProjects);

    return res(ctx.data(result));
  }),
];
