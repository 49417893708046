import * as R from "ramda";

import { DATA_TYPES } from "../../types";

export const makeResponseObject = R.curry((dataType: DATA_TYPES, result) => {
  const data = result.data ?? result;
  const total = result.total ?? result.length;

  return {
    [dataType]: data,
    [`${dataType}_aggregate`]: {
      aggregate: {
        count: total,
      },
    },
  };
});
