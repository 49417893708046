import { date } from "faker";
export { datatype, random } from "faker";

interface Options {
  dateBetween?: [Date, Date];
  indexBetween?: [number, number];
}

/**
 * Generate random date.
 *
 * @param options Optional parameters. Can optionally contain the following properties:
 *                dateBetween - a tuple of dates, within which the random date will be generated.
 *                indexBetween - a tuple of indexes for which generated date is in dateBetween limits.
 * @param index   Index of an item to be checked against indexBetween range.
 *
 * @return        ISO date string.
 */
export const generateRandomISODateForIndex =
  ({ dateBetween, indexBetween }: Options = {}) =>
  (index?: number) => {
    if (!dateBetween) {
      return toUTC(date.past());
    }

    if (!index || !indexBetween) {
      return toUTC(
        dateBetween ? date.between(dateBetween[0], dateBetween[1]) : date.past()
      );
    }

    if (index >= indexBetween[0] && index <= indexBetween[1]) {
      return toUTC(date.between(dateBetween[0], dateBetween[1]));
    }

    return toUTC(date.past());
  };

const toUTC = (date: Date) =>
  new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
