import { rest } from "msw";

export const assetHandlers =
  process.env.NODE_ENV === "test"
    ? [
        rest.get("https://icon.resources.vonage.com/*", (req, res, ctx) =>
          res(ctx.body("dummy"))
        ),
      ]
    : [];
