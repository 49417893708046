export enum FILTER_TYPES {
  _eq = "_eq",
  _gte = "_gte",
  _ilike = "_ilike",
  _like = "_like",
  _lt = "_lt",
}
export type FilterTypes = { [key in FILTER_TYPES]?: string };
export type FilterParams = { [key: string]: FilterTypes | FilterParams };
export interface FilterProps {
  onFilter: (filter: FilterParams) => void;
}

export type PaginateParams = {
  page?: number;
  pageSize?: number;
};

export type SortDirections = "asc" | "desc";
export type SortParams = Record<string, SortDirections>[];
export interface APIParams {
  orderBy?: SortParams;
  page?: number;
  pageSize?: number;
  where?: FilterParams;
}

export interface GetEntityResponse {
  data: unknown;
  error: string;
  loading: boolean;
}
export interface GetEntitiesListResponse extends GetEntityResponse {
  pageSize?: number;
  total?: number;
}
