import { Applications, Deployments } from "../../generated/graphql";
import { DATA_TYPES } from "../types";
import { getRelation } from "../utils/data-relations/relation";

export const projects: Array<
  Pick<
    Applications,
    | "ApiAccountId"
    | "ApiApplicationId"
    | "created"
    | "id"
    | "name"
    | "Deployments"
  >
> = [
  {
    ApiAccountId: "751ddfd5",
    ApiApplicationId: "209c3869-2da0-4192-b541-4da824479985",
    Deployments: [
      getRelation<Deployments>(DATA_TYPES.deployment, "deplFoo"),
      getRelation<Deployments>(DATA_TYPES.deployment, "deplBaz"),
      getRelation<Deployments>(DATA_TYPES.deployment, "deplGrault"),
    ],
    created: "2021-07-09T17:03:58.633388",
    id: "projFoo",
    name: "Foo",
  },
  {
    ApiAccountId: "111ddfd2",
    ApiApplicationId: "bb27b339-403b-4x87-8a31-76ea799d4296",
    Deployments: [
      getRelation<Deployments>(DATA_TYPES.deployment, "deplBar"),
      getRelation<Deployments>(DATA_TYPES.deployment, "deplQux"),
      getRelation<Deployments>(DATA_TYPES.deployment, "deplQuux"),
    ],
    created: "2021-07-08T12:11:12.298518",
    id: "projBar",
    name: "Bar",
  },
  {
    ApiAccountId: "341edfd5",
    ApiApplicationId: "09bz88d1-eb54-4668-b0e9-062dcf8947545",
    Deployments: [
      getRelation<Deployments>(DATA_TYPES.deployment, "deplCorge"),
      getRelation<Deployments>(DATA_TYPES.deployment, "deplGarply"),
    ],
    created: "2021-07-07T02:12:01.573821",
    id: "projBaz",
    name: "Baz",
  },
  {
    ApiAccountId: "751ddfd5",
    ApiApplicationId: "45f3324a-92ff-4a68-ad75-19ad952849fb",
    Deployments: [],
    created: "2021-05-11T08:00:44.162173",
    id: "projQux",
    name: "Qux",
  },
  {
    ApiAccountId: "341edfd5",
    ApiApplicationId: "7ec1f69f-d72a-4a63-808f-74a065a7002e",
    Deployments: [],
    created: "2021-03-23T07:48:16.379508",
    id: "projQuux",
    name: "Quux",
  },
  {
    ApiAccountId: "751ddfd5",
    ApiApplicationId: "a8aeffe8-67d5-418e-989e-d0ea1e863c38",
    Deployments: [],
    created: "2021-03-06T14:37:22.080984",
    id: "projCorge",
    name: "Corge",
  },
  {
    ApiAccountId: "111ddfd2",
    ApiApplicationId: "60d66dea-a8f2-4337-ba3a-ef2cd4a35b09",
    Deployments: [],
    created: "2021-02-14T05:25:48.621878",
    id: "projGrault",
    name: "Grault",
  },
  {
    ApiAccountId: "111ddfd2",
    ApiApplicationId: "3928bae1-1599-4f27-9de3-e7a0cade1c90",
    Deployments: [],
    created: "2021-01-22T00:00:47.350126",
    id: "projGarply",
    name: "Garply",
  },
];

export default projects;
