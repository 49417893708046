import { DATA_TYPES } from "../types";
import deployments from "./deployments";
import events from "./events";
import logs from "./logs";
import projects from "./projects";

export const schema = {
  [DATA_TYPES.deployment]: deployments,
  [DATA_TYPES.event]: events,
  [DATA_TYPES.log]: logs,
  [DATA_TYPES.project]: projects,
};
