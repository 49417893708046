import { schema } from "../../data";
import { DATA_TYPES, IndexItem } from "../../types";
import { Relation } from "./relation";

const getMatchingObject = (relation: Relation) => {
  if (Object.values(DATA_TYPES).includes(relation.__typename)) {
    const data: Array<IndexItem> = schema[relation.__typename];
    if (Array.isArray(data)) {
      return data.find(({ id }) => id === relation.id);
    }
  }
};

const traverseObject = (item: unknown): unknown => {
  if (item instanceof Relation) {
    return getMatchingObject(item);
  } else if (Array.isArray(item)) {
    return item.map(traverseObject);
  }

  return item;
};

const cloneObject = (data: IndexItem) => {
  const cloned = { ...data };

  for (const [key, value] of Object.entries(data)) {
    cloned[key] = traverseObject(value);
  }

  return cloned;
};

export const getRelations = (list: Array<IndexItem>) => list.map(cloneObject);
