import {
  graphql,
  GraphQLContext,
  GraphQLRequest,
  GraphQLVariables,
  ResponseComposition,
} from "msw";
import * as R from "ramda";

import { schema } from "../data";
import { DATA_TYPES } from "../types";
import { getRelations } from "../utils/data-relations/resolve-relations";
import { filterData } from "../utils/middleware/filter";
import { paginateData } from "../utils/middleware/paginate";
import { makeResponseObject } from "../utils/middleware/response-object";
import { sortData } from "../utils/middleware/sort";

const responseObject = makeResponseObject(DATA_TYPES.deployment);

const resolveDeploymentsQuery = (
  req: GraphQLRequest<GraphQLVariables>,
  res: ResponseComposition,
  ctx: GraphQLContext<Record<string, unknown>>
) => {
  const { limit, offset, orderBy, where } = req.variables;
  const allDeployments = schema[DATA_TYPES.deployment];

  const filterRules = filterData(where);
  const sortRules = sortData(orderBy);
  const paginateRules = paginateData(offset, limit);

  const result = R.pipe(
    getRelations,
    filterRules,
    sortRules,
    paginateRules,
    responseObject
  )(allDeployments);

  return res(ctx.data(result));
};

export const deploymentsHandlers = [
  graphql.query("getDeployments", resolveDeploymentsQuery),
  graphql.query("getConfig", resolveDeploymentsQuery),
];
