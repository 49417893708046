import styled from "styled-components";

interface ColumnProps {
  grow?: number;
  shrink?: number;
}

interface RowProps {
  align?: "center" | "flex-end" | "flex-start";
}

interface SpacerProps {
  size?: "S" | "M" | "L";
  vertical?: boolean;
}

export const Center = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
  height: 100%;
`;

export const Faded = styled.span`
  color: lightgray;
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Row = styled.div`
  justify-content: ${({ align = "flex-start" }: RowProps) => align};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 0%;
  flex-grow: ${({ grow = 1 }: ColumnProps) => grow};
  flex-shrink: ${({ shrink = 1 }: ColumnProps) => shrink};
`;

export const Spacer = styled.div`
  display: inline-block;
  padding: ${({ size, vertical }: SpacerProps) => {
    const padding = size === "S" ? 0.25 : size === "L" ? 1 : 0.5;

    return vertical ? `${padding}rem 0` : `0 ${padding}rem`;
  }};
`;

export const Text = styled.p`
  font-size: 1rem;
`;
