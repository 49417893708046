import { subMinutes } from "date-fns";
import * as R from "ramda";

import { Events } from "../../generated/graphql";
import {
  datatype,
  generateRandomISODateForIndex,
  random,
} from "../utils/random";

const now = new Date();
const generateTimestamp = generateRandomISODateForIndex({
  dateBetween: [subMinutes(now, 60), now],
  indexBetween: [10, 120],
});

const events: Array<
  Pick<
    Events,
    | "id"
    | "eventType"
    | "sourceType"
    | "applicationName"
    | "timestamp"
    | "instanceId"
    | "details"
  >
> = [
  {
    applicationName: "projFoo-dev",
    details:
      '{"from":{"type":"sms","number":"55544"},"to":{"type":"sms","number":"22456"},"content":{"type":"text","text":"What is your location ID?"}}',
    eventType: "provider.action.received",
    id: "evenFoo",
    sourceType: "provider",
  },
  {
    applicationName: "projFoo-dev",
    eventType: "callback.fired",
    id: "evenBar",
    instanceId: "instanceIdFoo",
    sourceType: "callback",
  },
  {
    applicationName: "projBar-dev",
    eventType: "callback.fired",
    id: "evenBaz",
    sourceType: "callback",
  },
  {
    applicationName: "projFoo-prod",
    eventType: "callback.subscribed",
    id: "evenQux",
    sourceType: "provider",
  },
  {
    applicationName: "projBar-prod",
    eventType: "provider.action.received",
    id: "evenQuux",
    sourceType: "provider",
  },
  {
    applicationName: "projBaz-dev",
    eventType: "callback.subscribed",
    id: "projCorge",
    sourceType: "provider",
  },
];

const randomEvents = (applicationName: string) =>
  R.range(1, 200).map(() => ({
    applicationName,
    eventType: `${random.word()}.${random.word()}`,
    id: datatype.uuid(),
    sourceType: random.word(),
  }));

const allEvents = [...events, ...randomEvents("projBar-dev")].map(
  (event, index): Events => ({
    instanceId: datatype.uuid(),
    timestamp: generateTimestamp(index),
    ...event,
  })
);

export default allEvents;
