import { subMinutes } from "date-fns";
import * as R from "ramda";

import { Logs } from "../../generated/graphql";
import {
  datatype,
  generateRandomISODateForIndex,
  random,
} from "../utils/random";

const now = new Date();
const generateTimestamp = generateRandomISODateForIndex({
  dateBetween: [subMinutes(now, 60), now],
});

const logs: Array<
  Pick<
    Logs,
    | "id"
    | "logLevel"
    | "sourceType"
    | "message"
    | "applicationName"
    | "timestamp"
    | "context"
    | "instanceId"
  >
> = [
  {
    applicationName: "projFoo-dev",
    id: "logFoo",
    instanceId: "instanceIdFoo",
    logLevel: "error",
    message: "message1",
    sourceType: "provider",
    timestamp: "2010-01-16T20:19:10.566Z",
  },
  {
    applicationName: "projFoo-dev",
    context: '{"payload":"audio", "voice_name":"Amy", "level":1}',
    id: "logBaz",
    instanceId: "instanceIdBaz",
    logLevel: "info",
    message: "message2",
    sourceType: "conversation",
  },
  {
    applicationName: "projFoo-dev",
    context: '{"payload":"audio", "voice_name":"Amy", "level":1}',
    id: "logBaz2",
    logLevel: "warning",
    message: "message3",
    sourceType: "video",
  },
  {
    applicationName: "projFoo-prod",
    id: "logBaz3",
    logLevel: "warning",
    message: "message3",
    sourceType: "video",
  },
  {
    applicationName: "projBar-prod",
    id: "logBar",
    logLevel: "warning",
    message: "message3",
  },
  {
    applicationName: "projBar-prod",
    id: "logBar2",
    logLevel: "others",
    message: "message4",
    sourceType: "sms",
  },
];

const randomLogs = (applicationName: string) =>
  R.range(1, 200).map(() => ({
    applicationName,
    id: datatype.uuid(),
    logLevel: `${random.word()}.${random.word()}`,
    message: random.word(),
    sourceType: random.word(),
  }));

const allLogs = [...logs, ...randomLogs("projFoo-prod")].map(
  (log, index): Logs => ({
    instanceId: datatype.uuid(),
    timestamp: generateTimestamp(index),
    ...log,
  })
);

export default allLogs;
