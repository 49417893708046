import "./index.css";

import { initVivid } from "@vonage/vivid-react";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

if (process.env.REACT_APP_MOCK) {
  require("./mocks/worker");
}

const rootElement = document.getElementById("root");

const renderApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    rootElement
  );
};

initVivid(rootElement, renderApp);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
